<template>
    <div class="TaskManagement">
         <div class="TaskManagement_left" :class="{TaskManagement_left1:show==false}">
                <el-menu class="el_menu" :default-active="$route.path" router>
                    <el-menu-item v-for="(item,index) in nav_menu_data" :key="index"  :index="item.path" v-show="show">
                                <span slot="title">{{item.title}}</span>
                    </el-menu-item>
                </el-menu>
         </div>
         <div class="TaskManagement_center">
               <img src="../../assets/image/guan.png" @click="close" v-show="show"/>
               <img src="../../assets/image/kai.png" @click="open" v-show="!show"/>
         </div>
         <div class="TaskManagement_right" :class="{TaskManagement_right1:show==false}">
             <router-view></router-view>
         </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            show:true,
            nav_menu_data: [{
                title: '项目信息',
                path: '/ProjectInformation'
            }, {
                title: '参研人员',
                path: '/Participants'
            }, {
                title: '科研过程',
                path: '/ScientificResearchProcess'
            },
            {
                title: '里程碑',
                path: '/milepost'
            },
            ],
        }
    },
    created(){
        console.log(1);
    },
    methods: {
    close() {//关
      this.$store.commit("showflag", true);
      this.show = false;
    },
    open(){//开
       this.$store.commit("showflag", false);
       this.show = true;
    }
  },
}
</script>
<style lang="scss" scoped>
.TaskManagement{
      width: 1920px;
      height: 100%;
    .TaskManagement_left{
        width: 140px;
        height: 100%;
        float: left;
        transition: width 1s;
        background-color: white;
        box-shadow: 3px 0 6px rgba(0, 104, 163, 0.1);
       .el_menu{
        width: 140px;
         span {
           font-size: 18px;
         }
       }
    }
    .TaskManagement_left1{
        width: 0px;
    }
    .TaskManagement_center{
        width: 30px;
        height: 100%;
        //background: chartreuse;
        float: left;
        position: relative;
        img {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 45%
        }
    }
    .TaskManagement_right{
        width: 1750px;
        height: 100%;
        float: left;
        transition: width 1s;
    }
    .TaskManagement_right1{
        width: 1890px;
    }
}
/deep/ .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 16px!important;
}
</style>
